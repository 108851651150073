<template>
    <div class="comData" :style="{paddingTop: landingPagePaddingTop + 'px', paddingBottom: landingPagePaddingBot + 'px'}">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <div v-for="(item, index) in pageComponentData" :key="index" :index="index">
                <component
                    v-if="item.component != 'v-text'"
                    :is="item.component"
                    :style="item.style"
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
                <component
                    :is="item.component"
                    v-else
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :style="getComponentStyle(item.style)"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
            </div>
        </el-form>
    </div>
</template>
 
<script>

import formItem from '../../minxins/form-item';
import { getQueryVariable } from '../../utils'
import { getFlowPageCacheById } from '../../api/index.js'
import { createSesameLink } from '@/api'
export default {
    name: 'flow-page',
    mixins: [formItem],

    data() {
        return {
            rules: {},
            ruleForm: {},
            landingPagePaddingBot: 0,
            landingPagePaddingTop: 0,
            // sesameLink: 'weixin://dl/business/?t=wapjdGOiHRv',
            // sesameLink: 'weixin://dl/business/?t=zz7URDcmzPr'
            sesameLink: ''

        }
    },
    computed: {
        pageComponentData() {
            return this.$store.state.flowPage.flowPageComponentData;
        }
    },
    watch: {
      pageComponentData: {
        handler(newVal) {
          this.$nextTick(() => {
             let elTH = 0, elBH = 0;
              newVal.map(item => {
                if (item.style.isDown === 0) {//组件打开了吸底
                  elBH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                } else if (item.style.isUp === 0) {//组件打开了吸顶
                  elTH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                }
              }, 0)
              // 画布顶部、底部内边距
              this.landingPagePaddingTop = elTH
              this.landingPagePaddingBot = elBH
          })
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
        console.log('created')
        // this.getSesameLink()
        // 本地测试数据
        // let testingData = require('../../assets/data/testing-data.json');
        // this.$store.commit('setPerview', true);
        // this.$store.commit('setComponentData', testingData.data.flowPageFormVOList);
        // this.$store.commit('setFlowPageAllData', testingData.data);
        // this.setFormValidator(this.$store.state.flowPage.pageComponentData);
        let id = getQueryVariable('id'), token =  getQueryVariable('token');
        sessionStorage.setItem("galaxyToken", token)
         if (getQueryVariable('perview') === 'true') {//落地页，预览模式
            getFlowPageCacheById(id).then(res => {
                if (res.code == 'SUCCESS') {
                    this.$store.commit('setPerview', true);
                    this.$store.state.flowPage.flowPageAllData.putMode = getQueryVariable('putMode')
                    this.$store.commit('setFlowPageAllData', this.$store.state.flowPage.flowPageAllData);
                    this.$store.commit('setComponentData', res.data);
                    this.setFormValidator(this.pageComponentData);
                } else {
                    this.$message.error('加载失败,请稍后重试！');
                }
            });
        } else if (id) {//用户打开落地页, id落地页的ID
            //获取落地页组件详情
           this.getFlowPageById(id)
           
           this.getUrlParams();//获取巨量引擎参数
        }
    },
    methods: {
        // 按钮点击事件
        hanlderBtn(btnType, prop) {
            console.log(btnType)
            // this.submitPay();
            // this.submitPay();
            if (btnType === 'v-button') {//普通按钮
                this.submitBtn(this.pageComponentData, prop, this.sesameLink);
            } else if (btnType === 'v-buttonPay' || btnType === 'v-pay') {
                this.submitBtn(this.pageComponentData);
            } else if (btnType === 'v-PhoneText') {//手机验证码，先收集用户数据
                this.submitFormData(this.pageComponentData)
            }
        },
        // 预览模式跳转
        navigationTo() {
            if (this.$store.state.flowPage.flowPageAllData.putMode == 1) {//投放模式: 0为普通，1为高转
                this.$router.push('message-page');
            } else {//投放模式: 0 普通，1为高转
                this.$router.push('tip-page');
            }
        },
        getSesameLink(flowId) {
            return new Promise((resolve,reject)=>{
                createSesameLink({
                    path: '/pages/webview/webview',
                    sense: `h5Url=${window.encodeURIComponent(process.env.VUE_APP_SYS_URL + 'addTeacherCode' + '?id=' + flowId)}`
                })
                    .then(res => {
                        if (res.code === "SUCCESS") resolve(res) 
                    })
            })
         
        //   createSesameLink({
        //     path: '/pages/webview/webview',
        //     sense: `h5Url=${window.encodeURIComponent(process.env.VUE_APP_SYS_URL+'addTeacherCode')}`
        //   })
        //   .then(res => {
        //     this.sesameLink = res.data
        //   })
        }
    }
}
</script>
 
<style lang="scss" scoped>
@import url('../../styles/form-item.scss');
</style>